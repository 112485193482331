import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId} from '../../shared/utils';
import i18n from '../../shared/i18n/i18n';


import FieldText from '../../SharedComponent/FieldText/FieldText';
import FieldCheck from '../../SharedComponent/FieldCheck/FieldCheck';
import PersonalDataAgreement from '../../SharedComponent/PersonalDataAgreement/PersonalDataAgreement';

export default class Form extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showAgreement: false,
            agreement: false,
            enterButtonEnabled: true,
        };

        this.ui = {
            enterButton: React.createRef(),
            login: React.createRef(),
            password: React.createRef(),
            rememberMe: React.createRef(),
        };

        this.onEnter = this.onEnter.bind(this);
        this.onRecoveryPassword = this.onRecoveryPassword.bind(this);
    }

    onEnter() {
        this.ui.login.current.setError();
        this.ui.password.current.setError();

        let login = this.ui.login.current.getValue();
        let password = this.ui.password.current.getValue();
        let rememberMe = this.ui.rememberMe.current.getValue();

        let hasError = false;

        if (login.length === 0) {
            this.ui.login.current.setError("NOTE_ENTER_DATA");
            hasError = true;
        }

        if (password.length === 0) {
            this.ui.password.current.setError("NOTE_ENTER_DATA");
            hasError = true;
        }

        if (!hasError) {

            this.props.message.current.hide();
            this.props.spinner.current.show();

            fetch('api/request.php', {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body: JSON.stringify({
                    action: 'login',
                    payload: {
                        login,
                        password,
                        rememberMe,
                        agreement: this.state.agreement,
                    }
                })
            })
                .then(response => {
                    return response.json();
                })
                .then(json => {
                    if (json.type !== 0) {
                        this.props.spinner.current.hide();
                        this.props.message.current.setMessage(json.code, 'danger', false);

                        if (json.code === 'ERR_PERSON_DATA_AGREEMENT') {
                            this.setState({showAgreement: true, agreement: false, enterButtonEnabled: false});
                        }
                        return;
                    }

                    window.location.href = '/' + json.data.url;

                })
                .catch(error => {
                    debugger;
                    this.props.spinner.current.hide();
                    this.props.message.current.setMessage("ERR_UNKNOWN", 'danger', false);
                });
        }
    }

    onRecoveryPassword() {
        window.open(`recoveryPassword.php?lang=${this.props.setting.lang}`, '_self');
    }

    render() {
        return (
            <div className="fb-row fb-c-c">
                <div className="fb-col fb-c-c w400px">
                    <div className="fb-col w200px">
                        <FieldText ref={this.ui.login} setting={this.props.setting} option={{
                            label: "LOGIN",
                            type: "text",
                            value: "",
                            onKeyPress: function (key) {
                                if (key === 'Enter') {
                                    this.ui.password.current.focus();
                                }
                            }.bind(this)
                        }}/>
                        <FieldText ref={this.ui.password} setting={this.props.setting} option={{
                            label: "PASSWORD",
                            type: "password",
                            value: "",
                            onKeyPress: function (key) {
                                if (key === 'Enter') {
                                    this.ui.rememberMe.current.focus();
                                }
                            }.bind(this)
                        }}/>
                    </div>
                    {this.state.showAgreement &&
                    <PersonalDataAgreement setting={this.props.setting} option={{
                        isShort: true,
                        onChange: (value) => {
                            this.setState({enterButtonEnabled: value, agreement: value});
                        }
                    }}/>
                    }
                    <div className="fb-col w200px">
                        <FieldCheck ref={this.ui.rememberMe} setting={this.props.setting} option={{
                            label: "REMEMBER_ME",
                            onKeyPress: function (key) {
                                if (key === 'Enter') {
                                    this.ui.enterButton.current.focus();
                                    this.state.enterButtonEnabled && this.onEnter();
                                }
                            }.bind(this)
                        }}/>
                        <button ref={this.ui.enterButton} className="btn btn-success m5rem" onClick={e => this.onEnter()} disabled={!this.state.enterButtonEnabled}>{i18n("VERB_ENTER", this.props.setting.lang)}</button>
                        {!this.state.showAgreement &&
                        <button className="btn btn-link m5rem" onClick={e => this.onRecoveryPassword()}>{i18n("RECOVERY_PASSWORD", this.props.setting.lang)}</button>
                        }
                    </div>
                </div>
            </div>
        );
    }

}