import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId} from '../../shared/utils';
import i18n from '../../shared/i18n/i18n';
import FieldComponent from '../FieldComponent/FieldComponent';

export default class FieldCheck extends FieldComponent {

    constructor(props) {
        super(props);

        this.state.value = (this.props.option.value === true) ? true : false;
    }

    render() {
        return (
            <div className="field-wrap">
                <div className="fb-col">
                    <div className="fb-row fb-s-c">
                        <input id={this.labelId} ref={this.ui} type="checkbox" onChange={e => this.onChange(e.currentTarget.checked)} onKeyPress={this.onKeyPress} checked={this.state.value} disabled={!this.state.enabled}/>
                        {this.props.option.label &&
                        <label htmlFor={this.labelId} className="label">{i18n(this.props.option.label, this.props.setting.lang)}</label>
                        }
                    </div>
                    <div className="help">{i18n(this.props.option.help, this.props.setting.lang)}</div>
                </div>

            </div>
        );
    }

}