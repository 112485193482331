import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId} from '../../shared/utils';



export default class LangSwitcher extends Component {

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(lang) {
        if (lang === this.props.setting.lang) return;

        this.props.setting.eventManager.dispatch('changelang', lang);
    }

    render() {
        return (
            <div id="Langs" className="fb-row m01rem mr-0">
                <div className={this.props.setting.lang === 'ru' ? "btn-pseudo font-weight-bold" : "btn"} onClick={e => this.onClick('ru')}>Рус</div>
                <div className={this.props.setting.lang === 'en' ? "btn-pseudo font-weight-bold" : "btn"} onClick={e => this.onClick('en')}>Eng</div>
            </div>
        );
    }

}
