import React from 'react';
import ReactDOM from 'react-dom';

import Login from './Login';

var createLogin = function (selector, setting) {
    if (!setting || Object.getOwnPropertyNames(setting).length === 0) {
        setting = {};
    }

    return ReactDOM.render(
        <Login setting={setting}/>,
        document.body.querySelector(selector)
    );
};

window.app.createLogin = createLogin;