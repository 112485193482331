let lastId = 0;

function generateId(prefix = '', postfix = '') {
    lastId++;
    return `${prefix}_${lastId}_${postfix}`;
}

function abcSort(a, b) {
    return a['name'].localeCompare(b['name']);
}

function langSupport(self) {
    self.state.lang = self.props.setting.lang;

    self.props.setting.eventManager.subscribe('changelang', function (lang) {
        this.setState({lang});
    }.bind(self));
}

function pageSettingAndLangSupport(self) {
    self.state.setting = self.props.setting;

    self.state.setting.eventManager.subscribe('changelang', function(lang) {
        let setting = this.state.setting;
        setting.lang = lang;
        this.setState({setting});
    }.bind(self));

}

export {
    generateId,
    abcSort,
    langSupport,
    pageSettingAndLangSupport,
}
