import React, {Component} from 'react';

import * as style from './FieldComponent.css';
import {generateId} from '../../shared/utils';
import classNames from "classnames";

export default class FieldComponent extends Component {

    constructor(props) {
        super(props);

        // TODO: check if this.props.option exists
        this.state = {
            value: this.props.option.value,
            initValue: this.props.option.value,
            data: this.props.option.data || [],
            error: this.props.option.error || '',
            enabled: this.props.option.enabled !== false,
            enabledError: this.props.option.enabledError !== false,
            className: this.props.option.className || '',
            postFieldText: this.props.option.postFieldText || '',
        };

        this.ui = React.createRef();
        this.uiSpinner = React.createRef();
        this.labelId = generateId('label', 'Field');

        this.onKeyPress = this.onKeyPress.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);
        this.getInitValue = this.getInitValue.bind(this);
        this.setInitValue = this.setInitValue.bind(this);
        this.setData = this.setData.bind(this);
        this.getData = this.getData.bind(this);
        this.setError = this.setError.bind(this);
        this.enabled = this.enabled.bind(this);
        this.getEnabled = this.getEnabled.bind(this);
        this.focus = this.focus.bind(this);
        this.getMark = this.getMark.bind(this);
        this.showSpinner = this.showSpinner.bind(this);
        this.getSpinner = this.getSpinner.bind(this);

    }

    onKeyPress(e) {
        if (this.props.option && this.props.option.onKeyPress) {
            this.props.option.onKeyPress(e.key);
        }
    }

    onBlur(e) {
        if (this.props.option && this.props.option.onBlur) {
            this.props.option.onBlur(e);
        }
    }

    onChange(value) {
        let ok = true;
        if (this.props.option && this.props.option.onChange) {
            ok = this.props.option.onChange(value);
        }

        if (ok) this.setState({value, error: ''});
    }

    setClassName(className) {
        this.setState({className});
    }

    setValue(value) {
        this.setState({value, error: ''});
    }

    getValue() {
        return this.state.value;
    }

    setInitValue(value) {
        this.setState({initValue: value});
    }

    getInitValue() {
        return this.state.initValue;
    }

    setData(data) {
        this.setState({data});
    }

    getData() {
        return this.state.data;
    }

    setError(error = '') {
        if (this.state.enabledError) this.setState({error});
    }

    enabled(enabled = true) {
        this.setState({enabled});
    }

    getEnabled() {
        return this.state.enabled;
    }

    focus() {
        this.ui.current.focus();
    }

    getMark() {
        if (this.props.option && this.props.option.mark) {
            return (
                <span className={this.props.option.mark.className}>{this.props.option.mark.symbol}</span>
            );
        } else {
            return null;
        }
    }
    showSpinner(visibility) {
        this.setState({spinnerVisibility: visibility});
    }

    getSpinner() {
        if (this.props.option && this.props.option.hasSpinner) {
            return (
                <div className={classNames(style["lds-ellipsis"], this.state.spinnerVisibility ? '' : 'invisible')}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            );
        } else {
            return null;
        }
    }

}