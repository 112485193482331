import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId, pageSettingAndLangSupport} from '../shared/utils';
import i18n from '../shared/i18n/i18n';


import Spinner from '../SharedComponent/Spinner/Spinner';
import Message from '../SharedComponent/Message/Message';
import HeaderGuest from '../SharedComponent/HeaderGuest/HeaderGuest';
import Form from '../Login/Form/Form';

export default class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.ui = {
            spinner: React.createRef(),
            message: React.createRef(),
            headerGuest: React.createRef(),
            form: React.createRef(),
        };


        pageSettingAndLangSupport(this);
    }

    render() {
        return (
            <div className="fb-col">
                <Spinner ref={this.ui.spinner}/>
                <HeaderGuest ref={this.ui.headerGuest} setting={this.props.setting} spinner={this.ui.spinner} message={this.ui.message}/>
                <div className="p1rem fz-15rem menu-bound">{i18n("ENTER", this.props.setting.lang)}</div>
                <Message ref={this.ui.message} hasPlace={true} setting={this.props.setting}/>
                <Form ref={this.ui.form} setting={this.props.setting} spinner={this.ui.spinner} message={this.ui.message}/>
            </div>
        );
    }

}