import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId} from '../../shared/utils';
import i18n from '../../shared/i18n/i18n';
import FieldComponent from '../FieldComponent/FieldComponent';

export default class FieldText extends FieldComponent {

    constructor(props) {
        super(props);

        this.state.value = this.props.option.value || '';
        this.state.size = this.props.option.size || 0;
        this.state.placeholder = this.props.option.placeholder || '';

        this.labelDataList = generateId('labelDataList', 'Field');

        this.inDataList = this.inDataList.bind(this);
    }

    inDataList(value, caseSensitive = true) {
        return this.state.data.find(item => {
            if (item.id && item.name) {
                return (caseSensitive) ? item.name.trim() === value.trim() : item.name.trim().toLowerCase() === value.trim().toLowerCase();
            } else {
                return false;
            }
        });
    }

    render() {
        return (
            <div className={classNames("field-wrap", this.state.className)}>
                <div className="fb-row fb-sb-c">
                    {this.props.option.label &&
                    <label htmlFor={this.labelId} className="label">{i18n(this.props.option.label, this.props.setting.lang)}{this.getMark()}</label>
                    }
                    {this.getSpinner()}
                </div>
                <div className="fb-col">
                    {this.state.enabledError &&
                    <div className={classNames(this.state.error.length === 0 && 'invisible', 'error')}>{i18n(this.state.error, this.props.setting.lang)}&nbsp;</div>
                    }
                    <div className='fb-row fb-s-c'>
                        <input id={this.labelId} ref={this.ui} type={this.props.option.type} onChange={e => this.onChange(e.currentTarget.value)} onKeyPress={this.onKeyPress} onBlur={this.onBlur} value={this.state.value} className={classNames(this.state.error.length > 0 ? 'error-border' : '', this.props.option.inputClassName, 'w100')} disabled={!this.state.enabled} size={this.state.size} list={this.labelDataList} placeholder={i18n(this.state.placeholder, this.props.setting.lang)}/>
                        <div className='m05rem mr-0'>{this.state.postFieldText}</div>
                    </div>
                    <datalist id={this.labelDataList}>
                        {this.state.data.map(function (item) {
                            return (
                                <option key={item.id} value={item.name}/>
                            );
                        }, this)}
                    </datalist>
                    <div className="help">{i18n(this.props.option.help, this.props.setting.lang)}</div>
                </div>
            </div>
        );
    }

}