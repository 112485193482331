import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId} from '../../shared/utils';
import i18n from '../../shared/i18n/i18n';

import FieldCheck from '../../SharedComponent/FieldCheck/FieldCheck';

export default class PersonalDataAgreement extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {isShort} = this.props.option;

        return (
            <div className='fb-col m1rem'>
                {this.props.setting.lang === 'ru' && isShort &&
                <p>Перед дальнейшим взаимодействием нам необходимо получить от вас</p>
                }
                {this.props.setting.lang === 'en' && isShort &&
                <p>Before going any further we need you to give us</p>
                }

                <FieldCheck setting={this.props.setting} option={{
                    label: "PERSONAL_DATA_AGREEMENT",
                    onChange: (value) => {
                        this.props.option.onChange(value);
                        return true;
                    },
                }}/>
                {this.props.setting.lang === 'ru' && isShort &&
                <>
                    <br/>
                    <p>Полный текст Политики конфиденциальности и обработки персональных данных вы можете прочитать <a href='https://www.formulo.org/ru/polconf/' target='_blank'>здесь</a>.</p>
                </>
                }
                {this.props.setting.lang === 'en' && isShort &&
                <>
                    <br/>
                    <p>The full text of the Policy about privacy and usage of the requested personal data is available <a href='https://www.formulo.org/en/confpolicy' target='_blank'>here</a>.</p>
                </>
                }


                {this.props.setting.lang === 'ru' && !isShort &&
                <>
                    <p className='font-italic'>В соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных" настоящим даю согласие на обработку моих персональных данных и персональных данных представляемого лица, включая (без ограничения) сбор, хранение, распространение и защиту информации. Полный текст Политики конфиденциальности и использования запрашиваемых персональных данных расположен <a href='https://www.formulo.org/ru/polconf/' target='_blank'>здесь</a>.</p>
                    <p className='font-italic'>Согласие дается свободно, своей волей и в своем интересе/в интересе представляемого лица.</p>
                </>
                }

                {this.props.setting.lang === 'en' && !isShort &&
                <>
                    <p className='font-italic'>In accordance with the Russian Federal law #152 about personal data and the corresponding laws in other countries, I hereby agree and consent for the processing of my own personal data and the personal data of the represented person, including (without limitation) the collection, storage, dissemination and protection of information. The full text of the Policy about privacy and usage of the requested personal data is available <a href='https://www.formulo.org/en/confpolicy' target='_blank'>here</a>.</p>
                    <p className='font-italic'>I give my agreement freely, on my own free will and in the interest of both myself and the represented person.</p>
                </>
                }

            </div>
        );
    }

}