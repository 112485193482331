import React, {Component, Fragment} from 'react';

import classNames from 'classnames';

import * as style from './Spinner.css';

export default class Spinner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visibility: false,
        };

        this.counter = 0;

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    show() {
        this.counter++;

        if (this.state.visibility) return;

        this.setState({visibility: true});
    }

    hide(force = false) {

        if (force) {
            this.counter = 0;
        } else {
            this.counter = this.counter > 0 ? this.counter - 1 : 0;
        }

        if (!this.state.visibility) return;

        this.setState({visibility: this.counter > 0});
    }

    render() {
        return (
            <div className={classNames(style.spinner, (this.state.visibility) ? '' : 'hidden')}>
                <div className={style['lds-dual-ring']}></div>
            </div>
        );
    }

}