import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId} from '../../shared/utils';
import i18n from '../../shared/i18n/i18n';

export default class Message extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visibility: false,
            message: '',
            textMessage: '',
            style: '',
            showCloseButton: true,
        };

        this.setMessage = this.setMessage.bind(this);
        this.hide = this.hide.bind(this);

    }

    setMessage(message, type = 'danger', showCloseButton = true, timeOut) {

        this.setState({
            visibility: true,
            message,
            textMessage: '',
            style: 'alert-' + type,
            showCloseButton,
        });

        if (timeOut) {
            setTimeout( () => this.hide(), timeOut);
        }
    }

    setTextMessage(textMessage, type = 'danger', showCloseButton = true, timeOut) {

        this.setState({
            visibility: true,
            message: '',
            textMessage,
            style: 'alert-' + type,
            showCloseButton,
        });

        if (timeOut) {
            setTimeout( () => this.hide(), timeOut);
        }
    }

    hide() {
        if (!this.state.visibility) return;

        this.setState({visibility: false});
    }

    render() {
        return (
            <div className={classNames("alert", this.state.visibility ? '' : (this.props.hasPlace ? 'invisible' : 'hidden'), this.state.style)}>
                {this.state.showCloseButton && <div className="close" onClick={e => this.hide()}><span>&times;</span></div>}
                {this.state.message.length > 0 && i18n(this.state.message, this.props.setting.lang)}
                {this.state.textMessage.length > 0 && this.state.textMessage}
            </div>
        );
    }

}