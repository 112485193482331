// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FieldComponent__lds-ellipsis___3sku_ {\n    width: 36px;\n    height: 8px;\n    display: inline-block;\n    position: relative;\n    background-color: transparent;\n}\n.FieldComponent__lds-ellipsis___3sku_ div {\n    position: absolute;\n    width: 8px;\n    height: 8px;\n    animation-timing-function: cubic-bezier(0, 1, 1, 0);\n    background-color: hsl(var(--success-h), var(--success-s), var(--success-l));\n}\n.FieldComponent__lds-ellipsis___3sku_ div:nth-child(1) {\n    left: 1px;\n    animation: FieldComponent__lds-ellipsis1___1phu4 0.6s infinite;\n}\n.FieldComponent__lds-ellipsis___3sku_ div:nth-child(2) {\n    left: 1px;\n    animation: FieldComponent__lds-ellipsis2___3Vk5P 0.6s infinite;\n}\n.FieldComponent__lds-ellipsis___3sku_ div:nth-child(3) {\n    left: 12px;\n    animation: FieldComponent__lds-ellipsis2___3Vk5P 0.6s infinite;\n}\n.FieldComponent__lds-ellipsis___3sku_ div:nth-child(4) {\n    left: 24px;\n    animation: FieldComponent__lds-ellipsis3___FzPPY 0.6s infinite;\n}\n@keyframes FieldComponent__lds-ellipsis1___1phu4 {\n    0% {\n        transform: scale(0);\n    }\n    100% {\n        transform: scale(1);\n    }\n}\n@keyframes FieldComponent__lds-ellipsis3___FzPPY {\n    0% {\n        transform: scale(1);\n    }\n    100% {\n        transform: scale(0);\n    }\n}\n@keyframes FieldComponent__lds-ellipsis2___3Vk5P {\n    0% {\n        transform: translate(0, 0);\n    }\n    100% {\n        transform: translate(12px, 0);\n    }\n}", ""]);
// Exports
exports.locals = {
	"lds-ellipsis": "FieldComponent__lds-ellipsis___3sku_",
	"lds-ellipsis1": "FieldComponent__lds-ellipsis1___1phu4",
	"lds-ellipsis2": "FieldComponent__lds-ellipsis2___3Vk5P",
	"lds-ellipsis3": "FieldComponent__lds-ellipsis3___FzPPY"
};
module.exports = exports;
