// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Spinner__spinner___3FzmZ {\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.3);\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 1001;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    box-sizing: content-box;\n}\n\n.Spinner__lds-dual-ring___1bmoa {\n    display: inline-block;\n    width: 56px;\n    height: 56px;\n}\n\n.Spinner__lds-dual-ring___1bmoa:after {\n    content: \" \";\n    display: block;\n    width: 46px;\n    height: 46px;\n    margin: 1px;\n    border-radius: 50%;\n    border: 5px solid #fff;\n    border-color: #fff transparent #fff transparent;\n    animation: Spinner__lds-dual-ring___1bmoa 1.2s linear infinite;\n}\n\n@keyframes Spinner__lds-dual-ring___1bmoa {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"spinner": "Spinner__spinner___3FzmZ",
	"lds-dual-ring": "Spinner__lds-dual-ring___1bmoa"
};
module.exports = exports;
