import React, {Component, Fragment} from 'react';

import classNames from 'classnames';
import {generateId} from '../../shared/utils';
import i18n from '../../shared/i18n/i18n';

import LangSwitcher from '../../SharedComponent/LangSwitcher/LangSwitcher';

export default class HeaderGuest extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.ui = {
            langSwitcher: React.createRef(),
        };

        this.onRegisterStudent = this.onRegisterStudent.bind(this);
        this.onRegisterOrganization = this.onRegisterOrganization.bind(this);
        this.onEnter = this.onEnter.bind(this);
        
    }

    onRegisterStudent() {
        window.location.href = 'studentReg.php?lang=' + this.props.setting.lang;
    }

    onRegisterOrganization() {
        window.location.href = 'organizationReg.php?lang=' + this.props.setting.lang;
    }

    onEnter() {
        window.location.href = 'index.php?lang=' + this.props.setting.lang;
    }

    render() {
        return (
            <div id="Header" className="fb-row fb-sb-c p1rem">
                <div className="fb-row fb-s-c fb-gr-1">
                    <img className="h70px" src="image/logo.png"/>
                    <div className="m01rem fz-15rem">{i18n("_COMPANY_NAME", this.props.setting.lang)}</div>
                    <div className="fb-row fb-gr-1 fb-e-c">
                        {false && this.props.setting.page === 'index' && <button className="btn btn-link" onClick={e => this.onRegisterOrganization()}>{i18n("REGISTRATION_ORGANIZATION", this.props.setting.lang)}</button>}

                        {this.props.setting.page === 'index' && <button className="btn btn-link" onClick={e => this.onRegisterStudent()}>{i18n("REGISTRATION", this.props.setting.lang)}</button>}
                        {this.props.setting.page !== 'index' && <button className="btn btn-link" onClick={e => this.onEnter()}>{i18n("ENTER", this.props.setting.lang)}</button>}
                    </div>
                </div>
                <LangSwitcher ref={this.ui.langSwitcher} setting={this.props.setting} spinner={this.props.spinner} message={this.props.message}/>
            </div>
        );
    }

}
