let i18nText = [];
i18nText['ru'] = require ('./i18n.ru.json');
i18nText['en'] = require ('./i18n.en.json');

export default function(key, lang) {

    if (key == null || key.length === 0) return '';

    if (!['ru', 'en'].includes(lang)) {
        lang = 'en';
    }

    let value = key;
    if (i18nText[lang].hasOwnProperty(key)) {
        value = i18nText[lang][key];
    }
    return value;
}
